<template>
    <div class="input-container" :class="{ 'disabled': disabled }" @keyup.enter="sendMessage">
        <input v-model="message" type="text" :placeholder="$t('messages.write_a_message')" :disabled="disabled">
        <fa-icon :icon="['fas', 'paper-plane']" fixed-width class="btn-send" @click="sendMessage" />
    </div>
</template>
<script>
export default {
    props: {
        disabled: {
            type:    Boolean,
            default: true,
        },
    },

    data() {
        return {
            message: '',
        };
    },

    computed: {
        selectedUser() {
            return this.$store.state.messages.selectedUser;
        },
    },

    methods: {
        sendMessage() {
            if (!this.message) return;
            this.$emit('messageSent', this.message);
            this.message = '';
        },
    },
};
</script>
<style scoped>
.input-container {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    background-color: #fff;
    height: 50px;
    width: 100%;
    border-top: 1px solid #ddd;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.input-container.disabled {
    background-color: #edf2f7;
}

input {
    outline: none;
    padding: 0 10px 0 15px;
    width: 100%;
    font-size: 15px;
}

input:disabled {
    background-color: initial;
}

.btn-send {
    margin-right: 10px;
    color: #bbb;
    cursor: pointer;
}
</style>
